import { gql } from 'apollo-boost'

import fragment from '../fragment/postBody'

export default gql`
  mutation insertPostBody(
    $post_id: ID!
    $post_urn: String!
    $body: String!
  ) {
    insertPostBody(
      post_id: $post_id
      post_urn: $post_urn
      body: $body
    ) {
      ...allPostBodyFields
    }
  }
  ${fragment.allPostBodyFields}
`