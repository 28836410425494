import { gql } from 'apollo-boost'

const allUrlFields = gql`
  fragment allUrlFields on Url {
    url
    expires_in
		created_at
  }
`

export default {
  allUrlFields
}