import { gql } from 'apollo-boost'

const allPostBodyFields = gql`
  fragment allPostBodyFields on PostBody {
    post_id
    post_urn
    body
		updated_at
		created_at
  }
`

export default {
  allPostBodyFields
}