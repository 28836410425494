import { gql } from 'apollo-boost'

import fragment from '../fragment/postMeta'

export default gql`
  query getPostMetas {
    getPostMetas {
      edges {
        node {
          ...allPostMetaFields
        }
      }
      page_info {
        has_next_page
        end_cursor
      }
    }
  }
  ${fragment.allPostMetaFields}
`