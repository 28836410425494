import React from 'react'
import PropTypes from 'prop-types'
import dateFormat from 'dateformat'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'

function desc (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getSorting (order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

const columns = [
  {id: 'title', numeric: false, disablePadding: false, label: 'Title'},
  {id: 'category', numeric: false, disablePadding: false, label: 'Category'},
  {id: 'author', numeric: false, disablePadding: false, label: 'Author'},
  {id: 'posted_at', numeric: false, disablePadding: false, label: 'Post Date'},
]

const styles = {
  root: {
    width: '100%',
    marginBottom: 30,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  row: {
    cursor: 'pointer',
  },
  toolbar: {},
  title: {
    flex: '0 0 auto',
  }
}

class PostTable extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      order: 'desc',
      orderBy: 'posted_at'
    }

    this.createSortHandler = this.createSortHandler.bind(this)
    this.handleRequestSort = this.handleRequestSort.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  render () {
    const {classes, posts} = this.props
    const {order, orderBy} = this.state

    return (
      <Paper className={classes.root}>
        {this.renderToolbar()}
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            {this.renderHead()}
            <TableBody>
              {posts
                .sort(getSorting(order, orderBy))
                .map(post => {
                  return (
                    <TableRow
                      className={classes.row}
                      hover
                      onClick={event => this.handleClick(event, post.post_id)}
                      tabIndex={-1}
                      key={post.post_id}
                    >
                      <TableCell>{post.title}</TableCell>
                      <TableCell>{post.category}</TableCell>
                      <TableCell>{post.author}</TableCell>
                      <TableCell>{dateFormat(new Date(post.posted_at * 1000), 'dd/mm/yyyy', true)}</TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    )
  }

  renderToolbar () {
    const {classes} = this.props

    return (
      <Toolbar
        className={classes.toolbar}
      >
        <div className={classes.title}>
          <Typography variant="h6" id="tableTitle">
            Posts
          </Typography>
        </div>
      </Toolbar>
    )
  }

  renderHead () {
    const {orderBy, order} = this.state

    return (
      <TableHead>
        <TableRow>
          {columns.map(column => {
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            )
          }, this)}
        </TableRow>
      </TableHead>
    )
  }

  createSortHandler (property) {
    return (event) => {
      this.handleRequestSort(event, property)
    }
  }

  handleRequestSort (event, property) {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({order, orderBy})
  }

  handleClick (event, id) {
    const {onSelectPost} = this.props
    if (onSelectPost) {
      onSelectPost(id)
    }
  }
}

PostTable.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PostTable)