import { gql } from 'apollo-boost'

import fragment from '../fragment/deployment'

export default gql`
  mutation deploy {
    deploy {
      ...allDeploymentFields
    }
  }
  ${fragment.allDeploymentFields}
`