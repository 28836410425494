import { gql } from 'apollo-boost'

import fragment from '../fragment/imageMeta'

export default gql`
  query getImageMetas {
    getImageMetas {
      edges {
        node {
          ...allImageMetaFields
        }
      }
      page_info {
        has_next_page
        end_cursor
      }
    }
  }
  ${fragment.allImageMetaFields}
`