import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {AuthComponent, states} from '@tleef/react-auth'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import AuthService from '../services/AuthService'

import Button from './Button'

const styles = theme => ({
  '@global html, body, #root': {
    height: '100%',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  form: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '80%',
    maxWidth: 320
  },
  logo: {
    marginBottom: theme.spacing.unit * 4,
    width: '100%',
  },
  white: {
    color: theme.palette.common.white,
    opacity: 0.87
  },
  button: {
    marginTop: theme.spacing.unit * 2,
    width: '100%'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
  error: {
    position: 'absolute',
    bottom: -theme.spacing.unit * 5,
  }
})

class SignIn extends AuthComponent {
  constructor (props) {
    super([states.signIn], props)

    this.state = {
      username: '',
      password: '',
      error: null,
      showPassword: false,
      usernameError: false,
      passwordError: false,
      disabled: true,
      loading: false,
    }

    this.authService = new AuthService()

    this.handleClickShowPassword = this.handleClickShowPassword.bind(this)
    this.handleChangeUsername = this.handleChangeUsername.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleSignIn = this.handleSignIn.bind(this)
  }

  showComponent () {
    const {classes} = this.props

    return (
      <div className={classes.root}>
        <div className={classes.form}>
          <img alt="Logo" className={classes.logo} src={'/logo.svg'}/>
          <TextField
            error={this.state.usernameError}
            fullWidth
            required
            id="username"
            label="Username, Email, or Phone Number"
            value={this.state.username}
            onChange={this.handleChangeUsername}
            helperText={this.state.usernameError ? 'Username is required.' : ' '}
          />
          <FormControl fullWidth>
            <InputLabel
              required
              htmlFor="password"
              error={this.state.passwordError}
            >
              Password
            </InputLabel>
            <Input
              fullWidth
              required
              id="password"
              type={this.state.showPassword ? 'text' : 'password'}
              error={this.state.passwordError}
              value={this.state.password}
              onChange={this.handleChangePassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}
                  >
                    {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText
              error={this.state.passwordError}
            >
              {this.state.passwordError ? 'Password is required.' : ' '}
            </FormHelperText>
          </FormControl>
          <div className={classes.actions}>
            <Button
              loading={this.state.loading}
              disabled={this.state.disabled}
              color="primary"
              className={classes.button}
              variant={'contained'}
              onClick={this.handleSignIn}
            >
              Sign In
            </Button>
          </div>
          {
            this.state.error &&
            <Typography
              className={classes.error}
              color="error"
            >
              {this.state.error}
            </Typography>
          }
        </div>
      </div>
    )
  }

  handleClickShowPassword () {
    this.setState(state => ({showPassword: !state.showPassword}))
  }

  handleChangeUsername (event) {
    const username = event.target.value
    const usernameError = !username
    const disabled = !username || !this.state.password

    this.setState({
      username,
      usernameError,
      disabled,
      error: null,
    })
  }

  handleChangePassword (event) {
    const password = event.target.value
    const passwordError = !password
    const disabled = !password || !this.state.username

    this.setState({
      password,
      passwordError,
      disabled,
      error: null,
    })
  }

  async handleSignIn () {
    this.setState({loading: true})

    const {username, password} = this.state

    try {
      const res = await this.authService.getToken({
        grant_type: 'password',
        username,
        password,
      })

      if (res) {
        if (res.status_code === 200) {
          return this.changeState(states.signedIn, res.data)
        } else if (res.status_code === 400 && res.error && res.error.message === 'invalid_grant') {
          return this.error('Incorrect username or password')
        }
      }
    } catch (e) {
      // do nothing
    } finally {
      this.setState({loading: false})
    }

    this.error('Error while authenticating')
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SignIn)