import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { Query } from 'react-apollo'

import GET_POST_METAS from '../data/graphql/query/getPostMetas'

import Layout from './Layout'
import PostTable from './PostTable'
import Fab from './Fab'

const styles = theme => ({
  root: {},
  addButton: {
    position: 'fixed',
    bottom: theme.spacing.unit * 4,
    right: theme.spacing.unit * 4,
  }
})

function PostsPage ({signOut, history, classes}) {
  return (
    <Query query={GET_POST_METAS}>
      {({loading, error, data}) => {
        if (error) {
          console.log(error)
          return (<div>{error.message}</div>)
        }

        const postMetas = data &&
          data.getPostMetas &&
          data.getPostMetas.edges &&
          data.getPostMetas.edges.length &&
          data.getPostMetas.edges.map((edge) => {
            return edge.node
          })

        return (
          <Layout
            signOut={signOut}
            loading={loading}
          >
            <PostTable
              posts={postMetas || []}
              onNewPost={() => {
                history.push('/post/new')
              }}
              onSelectPost={(id) => {
                history.push(`/post/${id}`)
              }}
            />
            <Fab
              variant="fab"
              className={classes.addButton}
              onClick={() => {
                history.push('/post/new')
              }}
            >
              <AddIcon/>
            </Fab>
          </Layout>
        )
      }}
    </Query>
  )
}

PostsPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PostsPage)