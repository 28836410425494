import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Layout from './Layout'
import PostEditor from './PostEditor'
import { Mutation, Query } from 'react-apollo'

import UPDATE_POST_META from '../data/graphql/mutation/updatePostMeta'
import UPDATE_POST_BODY from '../data/graphql/mutation/updatePostBody'
import GET_POST_METAS from '../data/graphql/query/getPostMetas'
import GET_POST_META from '../data/graphql/query/getPostMeta'
import GET_POST_BODY from '../data/graphql/query/getPostBody'

const styles = {
  root: {},
}

function PostPage ({signOut, match}) {

  const post_id = match.params.post_id

  return (
    <Query query={GET_POST_META} variables={{post_id}}>
      {({loading: loadingPostMeta, error: errorPostMeta, data: dataPostMeta}) => (
        <Query query={GET_POST_BODY} variables={{post_id}}>
          {({loading: loadingPostBody, error: errorPostBody, data: dataPostBody}) => {
            if (errorPostMeta || errorPostBody) {
              let error = errorPostMeta || errorPostBody
              console.log(error)
              return (<div>{error.message}</div>)
            }

            return (
              <Mutation
                mutation={UPDATE_POST_META}
                update={(cache, {data: {updatePostMeta}}) => {
                  try {
                    let {getPostMetas = {}} = cache.readQuery({query: GET_POST_METAS})
                    let edges = getPostMetas.edges || []
                    let page_info = getPostMetas.page_info || {has_next_page: false, __typename: 'PageInfo'}
                    let index = edges.findIndex((edge = {}) => {
                      let node = edge.node || {}
                      return node.post_id === updatePostMeta.post_id
                    })

                    if (index !== -1) {
                      edges[index] = {
                        __typename: 'PostMetaEdge',
                        node: updatePostMeta
                      }
                    } else {
                      edges.push({
                        __typename: 'PostMetaEdge',
                        node: updatePostMeta
                      })
                    }

                    getPostMetas = {
                      __typename: 'PostMetaConnection',
                      edges,
                      page_info
                    }

                    cache.writeQuery({
                      query: GET_POST_METAS,
                      data: {getPostMetas}
                    })
                  } catch (e) {
                    // Nothing in cache to update
                  }

                  cache.writeQuery({
                    query: GET_POST_META,
                    variables: {post_id: updatePostMeta.post_id},
                    data: {getPostMeta: updatePostMeta}
                  })
                }}
              >
                {(updatePostMeta, {loading: updatingPostMeta}) => (
                  <Mutation
                    mutation={UPDATE_POST_BODY}
                    update={(cache, {data: {updatePostBody}}) => {
                      cache.writeQuery({
                        query: GET_POST_BODY,
                        variables: {post_id: updatePostBody.post_id},
                        data: {getPostBody: updatePostBody}
                      })
                    }}
                  >
                    {(updatePostBody, {loading: updatingPostBody}) => {
                      const postMeta = dataPostMeta && dataPostMeta.getPostMeta
                      const postBody = dataPostBody && dataPostBody.getPostBody
                      const loading = (loadingPostMeta || loadingPostBody)

                      return (
                        <Layout
                          contentCentered
                          loading={loading}
                          signOut={signOut}
                        >
                          {
                            !loading && (
                              <PostEditor
                                saving={updatingPostMeta || updatingPostBody}
                                post={Object.assign({}, postMeta, postBody)}
                                onSave={(post) => {
                                  const {
                                    title,
                                    slug,
                                    category,
                                    author,
                                    image,
                                    description,
                                    posted_at,
                                    body,
                                  } = post

                                  updatePostMeta({
                                    variables: {
                                      post_id: postMeta.post_id,
                                      title,
                                      slug,
                                      category,
                                      author,
                                      image,
                                      description,
                                      posted_at,
                                    }
                                  })

                                  updatePostBody({
                                    variables: {
                                      post_id: postMeta.post_id,
                                      body
                                    }
                                  })
                                }}
                              />
                            )
                          }
                        </Layout>
                      )
                    }}
                  </Mutation>
                )}
              </Mutation>
            )
          }}
        </Query>
      )}
    </Query>
  )
}

PostPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PostPage)