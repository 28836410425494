import axios from 'axios'

export default class AuthService {
  constructor () {
    this.baseUrl = 'https://api.inside.melitorres.com/v1/oauth'
  }

  getToken(body) {
    return this.post('/token', body)
  }

  async post(endpoint, body) {
    let data
    try {
      const res = await axios.post(this.baseUrl + endpoint, body, {
        headers: {
          Authorization: 'Bearer QUFBQlphQ3NiaHRYc1ZmOUllcEhjNG9nLVAwQTR6N1o6QUFBQlphQ3JxRkJDMU1RdEN4QkVRNEVFTFBqaWlIV2E='
        }
      })
      data = res.data
    } catch (e) {
      data = e.response.data
    }

    return data
  }
}