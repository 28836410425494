import { gql } from 'apollo-boost'

const allDeploymentFields = gql`
  fragment allDeploymentFields on Deployment {
    deployment_id
    start_time
    end_time
    current_phase
    status
  }
`

export default {
  allDeploymentFields
}