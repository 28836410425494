import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Zoom from '@material-ui/core/Zoom'

const styles = theme => ({
  root: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
    color: theme.palette.primary.light,
  },
})

function MeliButton ({classes, className, onClick, loading, children, ...others}) {
  const handleClick = loading ? noop : onClick

  return (
    <div className={classNames(classes.root, className)}>

      <Zoom
        in={true}
        unmountOnExit
      >
        <Button onClick={handleClick} {...others}>
          {children}
        </Button>
      </Zoom>
      {loading && <CircularProgress size={68} className={classes.progress}/>}
    </div>
  )
}

MeliButton.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MeliButton)

function noop () {}