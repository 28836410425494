import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import MenuIcon from '../../node_modules/@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'

const styles = {
  icon: {
    marginRight: 36,
  },
}

function DrawerIcon ({classes, onClick}) {
  return (
    <IconButton
      aria-label="Open drawer"
      onClick={onClick}
      className={classes.icon}
    >
      <MenuIcon/>
    </IconButton>
  )
}

DrawerIcon.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DrawerIcon)