import React from 'react'
import PropTypes from 'prop-types'
import {Redirect} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Layout from './Layout'

const styles = {
  root: {},
}

function HomePage (props) {
  const {signOut} = props

  return (
    <Layout
      signOut={signOut}
    >
      <Redirect to="/posts"/>
    </Layout>
  )
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(HomePage)