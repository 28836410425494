import { gql } from 'apollo-boost'

import fragment from '../fragment/postMeta'

export default gql`
  query getPostMeta ($post_id: ID!) {
    getPostMeta (post_id: $post_id) {
      ...allPostMetaFields
    }
  }
  ${fragment.allPostMetaFields}
`