import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ImagesIcon from '@material-ui/icons/Photo'
import PostsIcon from '@material-ui/icons/InsertDriveFile'

const styles = theme => ({
  root: {},
  link: {
    textDecoration: 'none',
    color: theme.palette.common.black,
  },
})

const DrawerNav = function ({classes}) {
  return (
    <List className={classes.root} component="nav">
      <Link className={classes.link} to="/posts">
        <ListItem button>
          <ListItemIcon>
            <PostsIcon/>
          </ListItemIcon>
          <ListItemText primary="Posts"/>
        </ListItem>
      </Link>
      <Link className={classes.link} to="/images">
        <ListItem button>
          <ListItemIcon>
            <ImagesIcon/>
          </ListItemIcon>
          <ListItemText primary="Images"/>
        </ListItem>
      </Link>
    </List>
  )
}

DrawerNav.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DrawerNav)