import React from 'react'
import PropTypes from 'prop-types'
import dateFormat from 'dateformat'
import QuillEditor from '@tleef/react-quill'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SaveIcon from '@material-ui/icons/Save'

import Fab from './Fab'

import '@tleef/react-quill/lib/themes/quill.core.css'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 30
  },
  details: {
    width: '100%',
    maxWidth: 826,
  },
  paper: {
    padding: 40,
    cursor: 'text',
    minHeight: 1066,
    width: '100%',
    maxWidth: 826,

    [theme.breakpoints.up('sm')]: {
      padding: 80,
    },
  },
  editor: {
    ...theme.typography.subheading,
  },
  summary: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  title: {
    paddingLeft: 16,
    paddingRight: 16,

    [theme.breakpoints.up('sm')]: {
      paddingLeft: 56,
      paddingRight: 56,
    },
  },
  saveButton: {
    position: 'fixed',
    bottom: theme.spacing.unit * 4,
    right: theme.spacing.unit * 4,
  }
})

class PostEditor extends React.Component {
  constructor (props) {
    super(props)

    const post = props.post || {}
    const title = post.title || ''
    const postedAt = post.posted_at ? new Date(post.posted_at * 1000) : new Date()

    this.state = {
      expanded: false,
      title,
      titleError: false,
      slug: post.slug || slug(title),
      slugError: false,
      category: post.category || '',
      categoryError: false,
      author: post.author || '',
      authorError: false,
      postedAt: dateFormat(postedAt, 'yyyy-mm-dd', true),
      postedAtError: false,
      image: post.image || '',
      imageError: false,
      description: post.description || '',
      descriptionError: false,
      body: post.body || ''
    }

    this.editor = React.createRef()

    this.focus = this.focus.bind(this)
    this.getBody = this.getBody.bind(this)
    this.getMeta = this.getMeta.bind(this)
    this.setBody = this.setBody.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeTitle = this.handleChangeTitle.bind(this)
    this.handleExpansionPanelChange = this.handleExpansionPanelChange.bind(this)
  }

  componentDidMount () {
    this.setBody(this.state.body)
  }

  render () {
    const {classes, saving} = this.props

    return (
      <div className={classes.root}>
        <ExpansionPanel
          className={classes.details}
          elevation={1}
          expanded={this.state.expanded}
          onChange={this.handleExpansionPanelChange}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
            <div className={classes.summary}>
              {
                this.state.expanded || !this.state.title ? (
                  <Typography variant="h6">Details</Typography>
                ) : (
                  <div className={classes.title}>
                    <Typography variant="h6">{this.state.title}</Typography>
                  </div>
                )
              }
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="title"
                  label="Title"
                  placeholder="Give your post a title!"
                  value={this.state.title}
                  error={this.state.titleError}
                  onChange={this.handleChangeTitle}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="slug"
                  label="Slug"
                  value={this.state.slug}
                  error={this.state.slugError}
                  onChange={this.handleChange('slug')}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  id="category"
                  label="Category"
                  value={this.state.category}
                  error={this.state.categoryError}
                  onChange={this.handleChange('category')}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  id="author"
                  label="Author"
                  value={this.state.author}
                  error={this.state.authorError}
                  onChange={this.handleChange('author')}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  id="postedAt"
                  label="Post Date"
                  type="date"
                  value={this.state.postedAt}
                  error={this.state.postedAtError}
                  onChange={this.handleChange('postedAt')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="image"
                  label="Image"
                  value={this.state.image}
                  error={this.state.imageError}
                  onChange={this.handleChange('image')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  multiline
                  fullWidth
                  id="description"
                  label="Description"
                  rowsMax="4"
                  value={this.state.description}
                  error={this.state.descriptionError}
                  onChange={this.handleChange('description')}
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <Paper
          className={classes.paper}
          onClick={this.focus}
          elevation={1}
        >
          <QuillEditor
            ref={this.editor}
            className={classes.editor}
          />
        </Paper>
        <Fab
          variant="fab"
          className={classes.saveButton}
          onClick={this.handleSave}
          loading={saving}
        >
          <SaveIcon/>
        </Fab>
      </div>
    )
  }

  focus () {
    this.editor &&
    this.editor.current &&
    this.editor.current.getEditor().focus()
  }

  getMeta () {
    const {
      title,
      slug,
      category,
      author,
      postedAt,
      image,
      description,
    } = this.state

    return {
      title,
      slug,
      category,
      author,
      postedAt,
      image,
      description,
    }
  }

  getBody () {
    return this.editor &&
      this.editor.current &&
      this.editor.current.getEditor().getText()
  }

  setBody (text) {
    return this.editor &&
      this.editor.current &&
      this.editor.current.getEditor().setText(text)
  }

  handleSave () {
    const {onSave} = this.props

    const {
      title,
      slug,
      category,
      author,
      postedAt,
      image,
      description,
    } = this.getMeta()

    let stateUpdate = {}

    if (!title) {
      stateUpdate = Object.assign(stateUpdate, {
        expanded: true,
        titleError: true
      })
    }

    if (!slug) {
      stateUpdate = Object.assign(stateUpdate, {
        expanded: true,
        slugError: true
      })
    }

    if (!category) {
      stateUpdate = Object.assign(stateUpdate, {
        expanded: true,
        categoryError: true
      })
    }

    if (!author) {
      stateUpdate = Object.assign(stateUpdate, {
        expanded: true,
        authorError: true
      })
    }

    if (!postedAt) {
      stateUpdate = Object.assign(stateUpdate, {
        expanded: true,
        postedAtError: true
      })
    }

    if (!image) {
      stateUpdate = Object.assign(stateUpdate, {
        expanded: true,
        imageError: true
      })
    }

    if (!description) {
      stateUpdate = Object.assign(stateUpdate, {
        expanded: true,
        descriptionError: true
      })
    }

    if (Object.keys(stateUpdate).length) {
      return this.setState(stateUpdate)
    }

    const body = this.getBody()

    if (onSave) {
      onSave({
        title,
        slug,
        category,
        author,
        image,
        description,
        body,
        posted_at: new Date(postedAt).getTime() / 1000
      })
    }
  }

  handleChangeTitle (event) {
    const title = event.target.value

    this.setState({
      title,
      titleError: false,
      slug: slug(title),
      slugError: false,
    })
  }

  handleChange (name) {
    return (event) => {
      this.setState({
        [name]: event.target.value,
        [`${name}Error`]: false,
      })
    }
  }

  handleExpansionPanelChange (event, expanded) {
    this.setState({expanded})
  }
}

PostEditor.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(PostEditor)

function slug (str) {
  str = str.trim()
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;'
  const to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------'
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}