import { gql } from 'apollo-boost'

import fragment from '../fragment/user'

export default gql`
  query getMe {
    getMe {
      ...allMeFields
    }
  }
  ${fragment.allMeFields}
`