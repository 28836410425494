import { gql } from 'apollo-boost'

import fragment from '../fragment/url'

export default gql`
  query getImageUploadUrl ($content_type: String!) {
    getImageUploadUrl (
      content_type: $content_type
    ) {
      ...allUrlFields
    }
  }
  ${fragment.allUrlFields}
`