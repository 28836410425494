import { gql } from 'apollo-boost'

const allPostMetaFields = gql`
  fragment allPostMetaFields on PostMeta {
    post_id
    post_urn
    title
    slug
    category
    author
    description
    image
    posted_at
		updated_at
		created_at
  }
`

export default {
  allPostMetaFields
}