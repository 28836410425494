import ApolloClient from 'apollo-boost'

import defaults from './local/defaults'
import typeDefs from './local/typeDefs'
import GET_AUTH from './local/getAuth'

const client = new ApolloClient({
  uri: 'https://api.inside.melitorres.com/v1/graphql',
  request: async operation => {
    const {data: {auth: {access_token}}} = await client.query({query: GET_AUTH, fetchPolicy: 'cache-only'})

    operation.setContext({
      headers: {
        Authorization: access_token ? `Bearer ${access_token}` : ''
      }
    })
  },
  clientState: {
    defaults,
    typeDefs
  }
})

export default client