import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Snackbar from '@material-ui/core/Snackbar'

import Fab from './Fab'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
    width: '100%',
    height: '100%',
  },

  dropzone: {
    flex: 1,
    width: '100%',
    height: '100%',
  },

  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    pointerEvents: 'none',
    transition: theme.transitions.create('opacity', {duration: theme.transitions.duration.shortest}),
    borderWidth: 4,
    borderColor: theme.palette.primary.main,
    borderStyle: 'dashed',
    borderRadius: 5,
    backgroundColor: 'rgba(255, 158, 187, .5)',
    width: '100%',
    height: '100%',
    opacity: 0,
  },

  active: {
    opacity: 1,
  },

  label: {
    color: theme.palette.common.white,
  },

  addButton: {
    position: 'fixed',
    bottom: theme.spacing.unit * 4,
    right: theme.spacing.unit * 4,
  }
})

class ImageUploader extends Component {
  constructor (props) {
    super(props)

    this.state = {
      active: false
    }

    this.dropzone = React.createRef()

    this.handleAdd = this.handleAdd.bind(this)
  }

  render () {
    let {classes, uploadImage, children} = this.props

    return (
      <React.Fragment>
        <div className={classes.root}>
          <div className={classNames(classes.overlay, {[classes.active]: this.state.active})}/>
          <Dropzone
            ref={this.dropzone}
            className={classes.dropzone}
            disableClick={true}
            accept='image/*'
            onDrop={async (files) => {
              this.setState({active: false})

              for (let file of files) {
                const upload = await uploadImage(file)
                upload.on('progress', (event) => {
                  console.log('event', event)
                })
                upload.on('response', (response) => {
                  console.log('response', response)
                })
              }
            }}
            onDragEnter={() => this.setState({active: true})}
            onDragLeave={() => this.setState({active: false})}
          >
            {children}
          </Dropzone>
        </div>
        <Snackbar
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          open={this.state.active}
          message={'Drop your images to upload.'}
        />
        <Fab
          variant="fab"
          className={classes.addButton}
          onClick={this.handleAdd}
        >
          <AddIcon/>
        </Fab>
      </React.Fragment>
    )
  }

  handleAdd () {
    setTimeout(() => {
      this.dropzone &&
      this.dropzone.current &&
      this.dropzone.current.open()
    }, 300)
  }
}

ImageUploader.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ImageUploader)