import { gql } from 'apollo-boost'

export default gql`
  query getAuth {
    auth @client {
      access_token
      token_type
      expires_in
      scope
    }
  }
`