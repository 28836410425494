import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { ApolloProvider } from 'react-apollo'
import {Authenticator, Authorized, states} from '@tleef/react-auth'

import NewPostPage from './components/NewPostPage'
import PostPage from './components/PostPage'
import PostsPage from './components/PostsPage'
import ImagesPage from './components/ImagesPage'
import HomePage from './components/HomePage'
import SignIn from './components/SignIn'
import AppLoading from './components/AppLoading'
import NotFound from './components/NotFound'

import client from './data/graphql/client'
import GET_AUTH from './data/graphql/local/getAuth'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ff9ebb',
      main: '#fe6b8b',
      dark: '#c7375e',
      contrastText: '#fff'
    }
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(254, 107, 139, .3)',

        '&$disabled': {
          background: 'rgba(0, 0, 0, 0.12)',
        },
      },
      extendedFab: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        height: 48,
        boxShadow: '0 3px 5px 2px rgba(254, 107, 139, .3)',
        color: '#fff',
        whiteSpace: 'nowrap',
      },
      fab: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        boxShadow: '0 3px 5px 2px rgba(254, 107, 139, .3)',
        color: '#fff',
      }
    },
  }
})

class App extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: true
    }

    this.handleStateChange = this.handleStateChange.bind(this)
  }

  render () {
    return (
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline/>
          <Authenticator onStateChange={this.handleStateChange}>
            <SignIn/>
            <Authorized validAuthStates={[states.loading, states.signedIn]}>
              {(state, data, onStateChange) => {
                if (this.state.loading) {
                  return (
                    <AppLoading/>
                  )
                }

                const signOut = () => {
                  eraseCookie('auth')
                  onStateChange(states.signedOut)
                }

                return (
                  <Router>
                    <Switch>
                      <Route
                        exact
                        path="/"
                        render={(props) => (<HomePage {...props} signOut={signOut}/>)}
                      />
                      <Route
                        path="/post/new"
                        render={(props) => (<NewPostPage {...props} signOut={signOut}/>)}
                      />
                      <Route
                        path="/post/:post_id"
                        render={(props) => (<PostPage {...props} signOut={signOut}/>)}
                      />
                      <Route
                        path="/posts"
                        render={(props) => (<PostsPage {...props} signOut={signOut}/>)}
                      />
                      <Route
                        path="/images"
                        render={(props) => (<ImagesPage {...props} signOut={signOut}/>)}
                      />
                      <Route component={NotFound}/>
                    </Switch>
                  </Router>
                )
              }}
            </Authorized>
          </Authenticator>
        </MuiThemeProvider>
      </ApolloProvider>
    )
  }

  async handleStateChange (state, data, onStateChange) {
    const signOut = () => {
      eraseCookie('auth')
      onStateChange(states.signedOut)
    }

    if (state === states.loading) {
      const auth = getCookie('auth')

      if (!auth) {
        return signOut()
      }

      try {
        const data = JSON.parse(auth)
        onStateChange(states.signedIn, data)
      } catch (e) {
        return signOut()
      }
    }
    else if (state === states.signedIn) {
      const auth = getCookie('auth')
      if (!auth) {
        setCookie('auth', JSON.stringify(data), data.expires_in)
      }

      client.writeQuery({
        query: GET_AUTH,
        data: {
          auth: Object.assign({__typename: 'Auth'}, data)
        }
      })

      this.setState({loading: false})
    }
  }
}

export default App

function setCookie (name, value, seconds) {
  let expires = ''
  if (seconds) {
    const date = new Date()
    date.setTime(date.getTime() + (seconds * 1000))
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

function getCookie (name) {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

function eraseCookie (name) {
  document.cookie = name + '=; Max-Age=-99999999; path=/'
}
