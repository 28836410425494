import { gql } from 'apollo-boost'

import fragment from '../fragment/postMeta'

export default gql`
  mutation updatePostMeta(
    $post_id: ID!
    $title: String
    $slug: String
    $category: String
    $author: String
    $description: String
    $image: String
    $posted_at: Int
  ) {
    updatePostMeta(
      post_id: $post_id
      title: $title
      slug: $slug
      category: $category
      author: $author
      description: $description
      image: $image
      posted_at: $posted_at
    ) {
      ...allPostMetaFields
    }
  }
  ${fragment.allPostMetaFields}
`