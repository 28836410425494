import { gql } from 'apollo-boost'

import fragment from '../fragment/postBody'

export default gql`
  mutation updatePostBody(
    $post_id: ID!
    $body: String
  ) {
    updatePostBody(
      post_id: $post_id
      body: $body
    ) {
      ...allPostBodyFields
    }
  }
  ${fragment.allPostBodyFields}
`