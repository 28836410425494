import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Link } from 'react-router-dom'
import Divider from '@material-ui/core/Divider'

const styles = theme => ({
  button: {
    textTransform: 'none',
  },
  picture: {
    height: 28,
    width: 28,
    borderRadius: 2,
    marginRight: theme.spacing.unit,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.common.black,
  }
})

class UserMenu extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      anchorEl: null
    }

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleOpen (event) {
    this.setState({anchorEl: event.currentTarget})
  }

  handleClose () {
    this.setState({anchorEl: null})
  }

  render () {
    const {classes, user, signOut} = this.props

    return (
      <React.Fragment>
        <Button className={classes.button} onClick={this.handleOpen}>
          <img alt="User" className={classes.picture} src={user.picture}/>
          <Typography variant="subtitle1">{user.preferred_username}</Typography>
        </Button>
        <Menu
          id="user-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem onClick={this.handleClose}>
            <Link
              to="/account"
              className={classes.link}
            >My Account</Link>
          </MenuItem>
          <MenuItem onClick={this.handleClose}>
            <Link
              to="/support"
              className={classes.link}
            >Support</Link>
          </MenuItem>
          <Divider/>
          <MenuItem
            onClick={() => {
              this.handleClose()
              signOut()
            }}
          >Sign Out
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  }
}

UserMenu.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UserMenu)