import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  '@global html, body, #root': {
    height: '100%',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: 50,
    width: '80%',
    maxWidth: 540
  },
  logo: {
    marginBottom: theme.spacing.unit * 4,
    width: '100%',
    maxWidth: 320
  },
})

function AppLoading (props) {
  const {classes} = props

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img alt="Logo" className={classes.logo} src={'/logo.svg'}/>
        <Typography variant="h1" gutterBottom>404</Typography>
        <Typography variant="h5" gutterBottom>The page you tried was not found.</Typography>
        <Typography>You may have typed the address incorrectly or you may have used an outdated link.
          If you found a broken link from another site or from our site, please email us.</Typography>
      </div>
    </div>
  )
}

AppLoading.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppLoading)