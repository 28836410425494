import { gql } from 'apollo-boost'

const allImageMetaFields = gql`
  fragment allImageMetaFields on ImageMeta {
    image_id
    image_urn
    url
    title
    alt
    format
    width
    height
    size
		updated_at
		created_at
  }
`

export default {
  allImageMetaFields
}