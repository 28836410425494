import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },
  logo: {
    marginBottom: theme.spacing.unit * 4,
    width: '80%',
    maxWidth: 320
  },
})

function AppLoading (props) {
  const {classes} = props

  return (
    <div className={classes.root}>
      <img alt="Logo" className={classes.logo} src={'/logo.svg'}/>
    </div>
  )
}

AppLoading.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AppLoading)