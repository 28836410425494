import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Query, Mutation } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Divider from '@material-ui/core/Divider'
import LinearProgress from '@material-ui/core/LinearProgress'
import ListItem from '@material-ui/core/ListItem/ListItem'
import Button from '@material-ui/core/Button/Button'
import CloudUpload from '@material-ui/icons/CloudUpload'
import List from '@material-ui/core/List/List'

import GET_ME from '../data/graphql/query/getMe'

import AppLoading from './AppLoading'
import DrawerIcon from './DrawerIcon'
import DrawerNav from './DrawerNav'
import UserMenu from './UserMenu'
import DEPLOY from '../data/graphql/mutation/deploy'

const styles = theme => ({
  root: {
    height: '100vh',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.common.white
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 12,
    paddingRight: 36,
  },
  toolbarSection: {
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    marginTop: 4,
    marginBottom: -4,
    height: 24,
    opacity: 0.87,
  },
  drawer: {
    whiteSpace: 'nowrap',
    width: 186,
    [theme.breakpoints.up('sm')]: {
      width: 240,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  toolbarSpacer: theme.mixins.toolbar,
  content: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    marginLeft: 0,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 240,
    },
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentClose: {
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 9,
    },
  },
  contentCentered: {
    marginLeft: 0,
  },
  publish: {
    marginLeft: -10,
    width: '100%',
  },
  publishText: {
    marginLeft: 10,
  }
})

class Layout extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false,
    }

    this.handleDrawerToggle = this.handleDrawerToggle.bind(this)
    this.handleCreateOrg = this.handleCreateOrg.bind(this)
    this.handleSelectOrg = this.handleSelectOrg.bind(this)
  }

  handleDrawerToggle () {
    const {onDrawerToggle} = this.props

    this.setState({open: !this.state.open})

    if (onDrawerToggle) {
      onDrawerToggle()
    }
  }

  handleCreateOrg () {
    const {history} = this.props
    history.push('/org/new')
  }

  handleSelectOrg (org) {
    this.props.history.push(`/org/${org.org_id}`)
  }

  render () {
    const {classes, children, drawer, contentCentered, loading, signOut} = this.props

    return (
      <Query query={GET_ME}>
        {({loading: loadingMe, error, data}) => {
          if (loadingMe) {
            return (<AppLoading/>)
          }
          if (error) {
            console.log(error)
            return (<div>{error.message}</div>)
          }

          const me = data && data.getMe

          return (
            <Mutation mutation={DEPLOY}>
              {(deploy) => {
                return (
                  <div className={classes.root}>
                    <AppBar
                      className={classes.appBar}
                      elevation={1}
                    >
                      <Toolbar className={classes.toolbar} disableGutters>
                        <div className={classes.toolbarSection}>
                          <DrawerIcon onClick={this.handleDrawerToggle}/>
                          <Link to="/"><img alt="Logo" className={classes.logo} src={'/logo.svg'}/></Link>
                        </div>
                        <div className={classes.toolbarSection}>
                          <UserMenu user={me} signOut={signOut}/>
                        </div>
                      </Toolbar>
                      {loading && (<LinearProgress/>)}
                    </AppBar>
                    <Drawer
                      variant="permanent"
                      elevation={1}
                      classes={{
                        paper: classNames(classes.drawer, !this.state.open && classes.drawerClose),
                      }}
                      open={this.state.open}
                    >
                      <div className={classes.toolbarSpacer}/>
                      <List component="nav">
                        <ListItem>
                          <Button variant="extendedFab" className={classes.publish} onClick={deploy}>
                            <CloudUpload/>
                            { this.state.open && <span className={classes.publishText}>Publish</span>}
                          </Button>
                        </ListItem>
                      </List>
                      <Divider/>
                      <DrawerNav/>
                      <Divider/>
                      {drawer}
                    </Drawer>
                    <main
                      className={classNames(
                        classes.content,
                        !this.state.open && classes.contentClose,
                        contentCentered && classes.contentCentered
                      )}
                    >
                      <div className={classes.toolbarSpacer}/>
                      {children}
                    </main>
                  </div>
                )
              }}
            </Mutation>
          )
        }}
      </Query>
    )
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withRouter(withStyles(styles, {withTheme: true})(Layout))