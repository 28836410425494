import { gql } from 'apollo-boost'

const allMeFields = gql`
  fragment allMeFields on Me {
    user_id
    user_urn
		name
		given_name
		family_name
		middle_name
		nickname
		preferred_username
		email
		email_verified
		phone_number
		phone_number_verified
		profile
		picture
		website
		gender
		birthdate
		zoneinfo
		locale
		updated_at
		created_at
  }
`

export default {
  allMeFields
}