import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  root: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -16,
    color: theme.palette.common.white,
  },
  hidden: {
    opacity: 0,
  }
})

function MeliButton ({classes, className, onClick, loading, children, ...others}) {
  const handleClick = loading ? noop : onClick

  return (
    <div className={classNames(classes.root, className)}>
      <Button onClick={handleClick} {...others}>
        <div className={classNames({[classes.hidden]: loading})}>
          {children}
        </div>
      </Button>
      {loading && <CircularProgress size={32} className={classes.progress}/>}
    </div>
  )
}

MeliButton.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MeliButton)

function noop () {}