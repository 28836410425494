export default `
  type Auth {
    access_token: String
    token_type: String
    expires_in: Int
    scope: String
  }

  type Query {
    auth: Auth
  }
`