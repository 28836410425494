import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Query } from 'react-apollo'
import axios from 'axios'
import EventEmitter from 'events'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Copy from '@material-ui/icons/FileCopy'
import ImageGrid from "@tleef/react-image-grid"

import GET_IMAGE_UPLOAD_URL from '../data/graphql/query/getImageUploadUrl'
import GET_IMAGE_METAS from '../data/graphql/query/getImageMetas'

import ImageUploader from './ImageUploader'

import Layout from './Layout'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100% - 70px)',
    marginBottom: 30,
  },
  dialog: {
    maxWidth: 480,
  },
  dialogText: {
    whiteSpace: 'nowrap'
  },
  dialogHeading: {
    display: 'flex',
    alignContent: 'center',
  },
  dialogTitle: {
    lineHeight: '48px',
    marginRight: 10,
  },
  image: {
    marginBottom: 24,
  }
}

class ImagesPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      image: null,
    }

    this.handleCloseDialog = this.handleCloseDialog.bind(this)
  }

  render () {
    let {classes, signOut} = this.props
    return (
      <Query query={GET_IMAGE_METAS}>
        {({loading, error, data, client}) => {
          if (error) {
            console.log(error)
            return (<div>{error.message}</div>)
          }

          const images = (data &&
            data.getImageMetas &&
            data.getImageMetas.edges &&
            data.getImageMetas.edges.length &&
            data.getImageMetas.edges.slice()
              .sort((a, b) => {
                return b.node.created_at - a.node.created_at
              }).map((edge) => {
              const meta = edge.node
              return {
                src: meta.url,
                width: meta.width,
                height: meta.height,
              }
            })
          ) || []

          return (
            <Layout
              signOut={signOut}
              loading={loading}
            >
              <div className={classes.root}>
                <ImageUploader
                  uploadImage={async (image) => {
                    const {data} = await client.query({
                      query: GET_IMAGE_UPLOAD_URL,
                      variables: {content_type: image.type},
                      fetchPolicy: 'no-cache'
                    })

                    const url = data && data.getImageUploadUrl && data.getImageUploadUrl.url

                    const ee = new EventEmitter()
                    const config = {
                      headers: {
                        'Content-Type': image.type
                      },
                      onUploadProgress: (event) => {
                        ee.emit('progress', event)
                      }
                    }

                    console.log(url)
                    console.log(image)
                    console.log(config)

                    axios.put(url, image, config).then((response) => {
                      ee.emit('response', response)
                    })

                    return ee
                  }}
                >
                  <ImageGrid
                    images={images}
                    rowHeight={360}
                    onClickThumbnail={(image) => {
                      this.setState({image})
                    }}
                  />
                </ImageUploader>
                {
                  this.state.image && (
                    <Dialog
                      open
                      scroll={'body'}
                      onClose={this.handleCloseDialog}
                      classes={{
                        paperWidthSm: classes.dialog
                      }}
                    >
                      <img
                        className={classes.image}
                        src={this.state.image.src}
                        alt={this.state.image.alt || ''}
                        style={{
                          height: this.state.image.height,
                          width: this.state.image.width,
                          margin: "auto",
                        }}
                      />
                      <DialogContent>
                        <div className={classes.dialogHeading}>
                          <Typography className={classes.dialogTitle} variant="subtitle1">URL</Typography>
                          <IconButton onClick={async () => {
                            await navigator.clipboard.writeText(this.state.image.src)
                          }}>
                            <Copy/>
                          </IconButton>
                        </div>
                        <Typography className={classes.dialogText}>{this.state.image.src}</Typography>
                        <br/>
                        <div className={classes.dialogHeading}>
                          <Typography className={classes.dialogTitle} variant="subtitle1">Markdown</Typography>
                          <IconButton onClick={async () => {
                            await navigator.clipboard.writeText(`![${this.state.image.title || ''}](${this.state.image.src})`)
                          }}>
                            <Copy/>
                          </IconButton>
                        </div>
                        <Typography
                          className={classes.dialogText}
                        >
                          ![{this.state.image.title}]({this.state.image.src})
                        </Typography>
                      </DialogContent>
                    </Dialog>
                  )
                }
              </div>
            </Layout>
          )
        }}
      </Query>
    )
  }

  handleCloseDialog () {
    this.setState({image: null})
  }
}

ImagesPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ImagesPage)