import { gql } from 'apollo-boost'

import fragment from '../fragment/postBody'

export default gql`
  query getPostBody ($post_id: ID!) {
    getPostBody (post_id: $post_id) {
      ...allPostBodyFields
    }
  }
  ${fragment.allPostBodyFields}
`