import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'
import { withStyles } from '@material-ui/core/styles'

import INSERT_POST_META from '../data/graphql/mutation/insertPostMeta'
import INSERT_POST_BODY from '../data/graphql/mutation/insertPostBody'
import GET_POST_METAS from '../data/graphql/query/getPostMetas'
import GET_POST_META from '../data/graphql/query/getPostMeta'
import GET_POST_BODY from '../data/graphql/query/getPostBody'

import Layout from './Layout'
import PostEditor from './PostEditor'

const styles = {
  root: {},
}

function NewPostPage ({signOut, history}) {

  return (
    <Mutation
      mutation={INSERT_POST_META}
      update={(cache, {data: {insertPostMeta}}) => {
        try {
          let {getPostMetas = {}} = cache.readQuery({query: GET_POST_METAS})
          let edges = getPostMetas.edges || []
          let page_info = getPostMetas.page_info || {has_next_page: false, __typename: 'PageInfo'}
          let index = edges.findIndex((edge = {}) => {
            let node = edge.node || {}
            return node.post_id === insertPostMeta.post_id
          })

          if (index !== -1) {
            edges[index] = {
              __typename: 'PostMetaEdge',
              node: insertPostMeta
            }
          } else {
            edges.push({
              __typename: 'PostMetaEdge',
              node: insertPostMeta
            })
          }

          getPostMetas = {
            __typename: 'PostMetaConnection',
            edges,
            page_info
          }

          cache.writeQuery({
            query: GET_POST_METAS,
            data: {getPostMetas}
          })
        } catch (e) {
          // Nothing in cache to update
        }

        cache.writeQuery({
          query: GET_POST_META,
          variables: {post_id: insertPostMeta.post_id},
          data: {getPostMeta: insertPostMeta}
        })
      }}
    >
      {(insertPostMeta, {loading: insertingPostMeta}) => (
        <Mutation
          mutation={INSERT_POST_BODY}
          update={(cache, {data: {insertPostBody}}) => {
            cache.writeQuery({
              query: GET_POST_BODY,
              variables: {post_id: insertPostBody.post_id},
              data: {getPostBody: insertPostBody}
            })
          }}
        >
          {(insertPostBody, {loading: insertingPostBody}) => (
            <Layout
              contentCentered
              signOut={signOut}
            >
              <PostEditor
                saving={insertingPostMeta || insertingPostBody}
                onSave={async (post) => {
                  const {
                    title,
                    slug,
                    category,
                    author,
                    image,
                    description,
                    posted_at,
                    body,
                  } = post

                  const res = await insertPostMeta({
                    variables: {
                      title,
                      slug,
                      category,
                      author,
                      image,
                      description,
                      posted_at,
                    }
                  })

                  const postMeta = res.data.insertPostMeta

                  await insertPostBody({
                    variables: {
                      post_id: postMeta.post_id,
                      post_urn: postMeta.post_urn,
                      body,
                    }
                  })

                  history.push(`/post/${postMeta.post_id}`)
                }}
              />
            </Layout>
          )}
        </Mutation>
      )}
    </Mutation>
  )
}

NewPostPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(NewPostPage)